import React from "react";
// import logo from './logo.svg';
import "./App.css";
import { Container, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import MyHeader from "./MyHeader";
import MyVideo from "./MyVideo";

function Cac() {
  return (
    <div className="App fluid">
      <MyHeader page="Congressional App Challenge" />

      <Container className="p-3 mt-5">
        <Card className="mb-3 Card-color Card-text mt-3 mt-lg-5">
          <Card.Body>
            <h1>Congressional App Challenge</h1>
            <Card.Text>
              The{" "}
              <a
                href="https://www.congressionalappchallenge.us/about/"
                target="_blank"
                rel="noreferrer nofollow"
              >
                Congressional App Challenge
              </a>{" "}
              is a nationwide competition run by members of the House of
              Representitives, with students competing in the creation of an
              app.
            </Card.Text>
            <Card.Title as="h2">What is Thrive?</Card.Title>
            <Card.Text>
              Our app, Thrive, is a family-focused private social network
              designed to ease the transition into independent living for people
              with intellectual and physical disabilities and their families.
              People in these situations often face challenges when moving away
              from their family home for the first time, foremost of which can
              simply be the logistics of daily life. Maintaining one’s mental
              health and wellbeing is also a huge challenge; adults with
              disabilities report experiencing frequent mental distress almost 5
              times as often as adults without.
              <br />
              <br />
              The app is intended to be a private communications hub for the
              family, helping to unobtrusively let everyone stay in touch with
              each other and keep on top of things like appointments,
              medications, and anything the newly independent family member
              needs. Keeping this information in one shared place can help these
              individuals stay organized, and bring peace of mind to their
              parents.
            </Card.Text>
            <MyVideo
              video={
                "https://www.youtube.com/embed/0dIc9ON09uw?si=iMXQi6Gq51AhVMLe"
              }
            />
          </Card.Body>
        </Card>
      </Container>
      <br />
      <br />
    </div>
  );
}

export default Cac;
