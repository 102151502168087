import React from "react";
// import logo from './logo.svg';
import "./App.css";
import { Container, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import MyHeader from "./MyHeader";

function Mesa() {
  return (
    <div className="App fluid">
      <MyHeader page="MESA" />

      <Container className="p-3 mt-5">
        <Card className="mb-3 Card-color Card-text mt-3 mt-lg-5">
          <Card.Body>
            <h1>MESA</h1>
            <Card.Text>
              The{" "}
              <a
                href="https://secwww.jhuapl.edu/stem/mesa"
                target="_blank"
                rel="noreferrer nofollow"
              >
                MESA
              </a>{" "}
              Challenge is a statewide competition run by the Johns Hopkins
              University Applied Physics Laboratory, with students competing in
              STEM-related challenges. We entered and won the high-school app
              development challenge, to develop an app that addresses a health
              issue in our society. Our entry was called Kinnect.
            </Card.Text>
            <Card.Title as="h2">What is Kinnect?</Card.Title>
            <Card.Text>
              According to the US Census Bureau, nearly one third of US seniors
              live alone. That means that nearly 14 million senior citizens have
              limited, or sometimes no daily access to caregivers and are
              becoming increasingly lonely.
              <br />
              <br />
              Research shows that isolation is linked to high blood pressure,
              increased risk of Alzheimer's, heart disease, depression,
              cognitive decline, and even death. According to Johns Hopkins
              researchers, socially isolated older adults have a 27% higher
              chance of developing dementia than older adults who are socially
              connected.
              <br />
              <br />
              According to another Johns Hopkins study, electronic devices can
              help to alleviate this isolation.
              <br />
              <br />
              Kinnect is an attempt to solve this problem.
            </Card.Text>
            <Card.Img src="/pictures/splash.png" />
            <Card.Text>This was my team's splash for our app</Card.Text>
            <Card.Img src="/pictures/displayBoard.jpg" />
            <Card.Text>My team displaying our app display board</Card.Text>
            <Card.Img src="/pictures/board.png" />
            <Card.Text>This is our app display board</Card.Text>
            <Card.Img src="/pictures/awardCeremony.jpg" />
            <Card.Text>Receiving our award</Card.Text>
            <Card.Img src="/pictures/stand.jpg" />
            <Card.Text>
              This is us manning our stand at the competition fair
            </Card.Text>
          </Card.Body>
        </Card>
      </Container>
      <br />
      <br />
    </div>
  );
}

export default Mesa;
