import React from "react";
// import logo from './logo.svg';
import "./App.css";
import { Container, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import MyHeader from "./MyHeader";
import MyVideo from "./MyVideo";

function Violin() {
  return (
    <div className="App fluid">
      <MyHeader page="Violin" />

      <Container className="p-3 mt-5">
        <Card className="mb-3 Card-color Card-text mt-3 mt-lg-5">
          <Card.Body>
            <h1>Violin</h1>
            <Card.Text>
              I have been a student with the Perpetual Motion Suzuki Strings
              violin studio since I was five years old.
            </Card.Text>
            <MyVideo
              video={
                "https://www.youtube.com/embed/oT0wMJjfn5M?si=SBeCOhFt25g7Mj71"
              }
            />
            <Card.Text>
              This is my recording of the fourth of Biber's twelve “mystery
              sonatas”. This piece is played in scordatura tuning A, D, A, D.
              Heinrich Biber was born in Bohemia in 1644 and worked from the
              1670's until the end of his life at the court of the archbishop in
              Salzburg.
              <br></br>
              <a
                href="https://baroque.boston/biber-the-mystery-sonatas"
                target="_blank"
                rel="nofollow noreferrer"
              >
                https://baroque.boston/biber-the-mystery-sonatas
              </a>
            </Card.Text>
            <Card.Img src="/violin/bsg1.jpg" />
            <Card.Img src="/violin/bsg2.jpg" />
            <Card.Text>
              In 2023 we performed at the British Suzuki Gala in the Royal
              Albert Hall, London
            </Card.Text>
            <Card.Img src="/violin/pianoGuys.jpg" />
            <Card.Text>
              In 2019 we performed with the Piano Guys at Wolf Trap National
              Park for the Performing Arts, Vienna, VA
            </Card.Text>
            <Card.Img src="/violin/earlyDays.jpg" />
            <Card.Text>The early days</Card.Text>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}

export default Violin;
