import React from "react";
// import logo from './logo.svg';
import "./App.css";
import { Container, Navbar } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";

function Home() {
  return (
    <div className="App" style={{ overflow: "hidden" }}>
      <header className="App-Name">
        <Navbar class="text-white" fixed="top" bg="dark" color="light">
          <Container>
            <Navbar.Brand>
              <img
                alt="e"
                src="/e.png"
                width="30"
                height="30"
                className="d-inline-block align-top"
              />{" "}
              <text class="text-white ms-2">Enda Hughes</text>
            </Navbar.Brand>
          </Container>
        </Navbar>
      </header>
      <br />
      <br />
      <br></br>
      <br></br>
      {/* <div className="info">Email: enda.omega@gmail.com</div>
      <br></br>
      <div className="info">Artist Profile: XORILACH</div> */}

      <div
        className="mx-auto"
        style={{
          pointerEvents: "none",
          position: "relative",
          width: "700px",
          height: "700px",
        }}
      >
        <span className="e">e</span>
        <span className="n">n</span>
        <span className="d">d</span>
        <span className="a">a</span>
      </div>
    </div>
  );
}

export default Home;
