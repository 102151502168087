function MyVideo({ video }) {
  return (
    <iframe
      style={{ aspectRatio: "560  / 315" }}
      src={video}
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
    ></iframe>
  );
}

export default MyVideo;
