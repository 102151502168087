import { Link } from "react-router-dom";

function MyLink({ link, image, alt }) {
  return (
    <Link
      className="btn btn-light p-3 mt-4 fade-in"
      to={link}
      style={{ boxShadow: "0 2px 6px rgba(0,0,0,.2)" }}
    >
      <img
        alt={alt}
        src={image}
        width="300"
        height={(image.height * image.width) / 300}
        className="d-inline-block align-top"
      />
    </Link>
  );
}

export default MyLink;
