import React from "react";
// import logo from './logo.svg';
import "./App.css";
import { Container, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import MyHeader from "./MyHeader";
import MyVideo from "./MyVideo";

function Cs50() {
  return (
    <div className="App fluid">
      <MyHeader page="CS50x" />

      <Container className="p-3 mt-5">
        <Card className="mb-3 Card-color Card-text mt-3 mt-lg-5">
          <Card.Body>
            <h1>CS50x</h1>
            <Card.Text>
              I completed the Harvard{" "}
              <a
                href="https://cs50.harvard.edu/x/2022/"
                target="_blank"
                rel="noreferrer nofollow"
              >
                CS50x
              </a>{" "}
              Introduction to Computer Science course. Topics included
              abstraction, algorithms, data structures, encapsulation, resource
              management, security, software engineering, and web programming.
              Languages included C, Python, and SQL plus HTML, CSS, and
              JavaScript.
            </Card.Text>
            <MyVideo
              video={
                "https://www.youtube.com/embed/xR4FFBu0SQs?si=uNqCQoz_kybLNEh3"
              }
            />
            <Card.Text>
              This is the video presentation for my final project
            </Card.Text>
            <embed
              style={{ aspectRatio: "640  / 485" }}
              src="/resources/edX.pdf#toolbar=0&navpanes=0&scrollbar=0"
              type="application/pdf"
            ></embed>
            <Card.Text>The course completion certificate</Card.Text>
          </Card.Body>
        </Card>
      </Container>
      <br />
      <br />
    </div>
  );
}

export default Cs50;
